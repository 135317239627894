import _helper from "../helper";
import _types from "../types";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.respond = exports.request = exports.Action = void 0;
var helper_1 = _helper;
var types_1 = _types;
var Action;
(function (Action) {
  Action["REQUEST"] = "APP::SESSION_TOKEN::REQUEST";
  Action["RESPOND"] = "APP::SESSION_TOKEN::RESPOND";
})(Action = exports.Action || (exports.Action = {}));
function request() {
  return helper_1.actionWrapper({
    group: types_1.Group.SessionToken,
    type: Action.REQUEST
  });
}
exports.request = request;
function respond(sessionToken) {
  return helper_1.actionWrapper({
    payload: sessionToken,
    group: types_1.Group.SessionToken,
    type: Action.RESPOND
  });
}
exports.respond = respond;
export default exports;
export const __esModule = exports.__esModule;
const _respond = exports.respond,
  _request = exports.request,
  _Action = exports.Action;
export { _respond as respond, _request as request, _Action as Action };